import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { ApiUrls } from 'src/app/util/common.enum';
import { DataResponseModel } from 'src/app/util/common.model';
import { environment } from 'src/environments/environment';
import {
  LocationMenuModel,
  LocationModel,
  SubLocationMenuModel
} from '../model/location.model';

@Injectable()
export class LocationService {

  private locationsList: LocationMenuModel[] = [];
  private locations$: Subject<LocationMenuModel[]> = new Subject<LocationMenuModel[]>();
  private locationFormChanges$: Subject<any> = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  getLocationData(selectedLocationId: string): Observable<LocationMenuModel[]> {
    return this.httpClient.get<DataResponseModel<LocationMenuModel[]>>(`${environment.baseUrl}${ApiUrls.GetLocations}`)
      .pipe(map((data) => this.mapMenudata(data, selectedLocationId)));
  }

  fetchLocationsList(selectedLocationId: string) {
    //console.log('fetching locations list', selectedLocationId);
    return this.httpClient.get<DataResponseModel<LocationMenuModel[]>>(`${environment.baseUrl}${ApiUrls.GetLocations}`)
      .pipe(map((data) => this.mapMenudata(data, selectedLocationId)))
      .subscribe((data: any) => {
        if (data) {
          this.setLocations(data);
        }
      });
  }

  getLocationFormChanges() {
    return this.locationFormChanges$.asObservable();
  }

  setLocationFormChanges(value: boolean) {
    this.locationFormChanges$.next(value);
  }

  getLocationsSub() {
    return this.locations$.asObservable();
  }

  getLocations() {
    return this.locationsList;
  }

  setLocations(locations: LocationMenuModel[]) {
    //console.log('setting locations list', locations);
    this.locationsList = locations;
    this.locations$.next(locations);
  }

  mapMenudata(data: DataResponseModel<LocationMenuModel[]>, selectedLocationId: string): LocationMenuModel[] {
    if (data.success && data.data.length) {
      return data.data.map((locationMenu: LocationMenuModel, index: number) => {
        if (locationMenu.subLocations) {
          locationMenu.subLocations = locationMenu.subLocations?.map((subAssetData: SubLocationMenuModel) => {
            return { ...subAssetData, isSelected: selectedLocationId === subAssetData.locationId, type: 'sublocation' }
          })
        }
        const isExist = locationMenu.subLocations?.some((value) => value.locationId === selectedLocationId);
        if (isExist) {
          locationMenu.isSelected = false;
        } else if (selectedLocationId) {
          locationMenu.isSelected = selectedLocationId === locationMenu.locationId;
        } else {
          locationMenu.isSelected = index === 0;
        }
        return { ...locationMenu, type: 'location' };
      });
    }
    return [];
  }

  saveLocation(locationData: LocationModel): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.AddLocation}`, locationData);
  }

  updateLocation(locationData: LocationModel): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.UpdateLocation}`, locationData);
  }

  deleteLocation(locationId: string): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}${ApiUrls.DeleteLocation}`, { locationId });
  }

  getTimezones() {
    return this.httpClient.get<DataResponseModel<LocationMenuModel[]>>(`${environment.baseUrl}${ApiUrls.getTimezones}`).pipe(map((data) => data.data));
  }

}
