<div class="bg-base block" style="min-height: 100%;">
  <div class="block relative">
    <div class="flex justify-center mb-6 pt-6">
      <img src="../../assets/images/supercmms-white.png" class="logo" alt="Super Cmms" />
    </div>
    <div class="block text-center mb-3">
      <div class="font-bold uppercase text-2xl mb-2">Report Issue</div>
      <div class="font-bold" *ngIf="locationFullPath">{{ locationFullPath }}</div>
      <div class="font-bold" *ngIf="assetFullPath">{{ assetFullPath }}</div>
    </div>
    <div class="flex justify-center text-center mb-6" *ngIf="!errorMessage">
      <div class="font-bold mw-300">Please fill in as many details as possible. Your email and mobile number helps us reach you to solve the issues faster.</div>
    </div>
    <app-report [form]="form" [formControls]="formControls" *ngIf="!errorMessage"
      [isSubmitting]="isLoading" (submitReport)="onSubmit($event)">
    </app-report>
    <div *ngIf="errorMessage" class="my-6 text-center font-bold text-rejected">This asset is no longer linked to CMMS.<br/> Please contact your Admin/Manager.</div>
    <div class="text-sm text-center mt-6 pb-3">Powered by supercmms.com</div>
  </div>
</div>